<template>
  <v-container class="listUtenti">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Utenti</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="utenti"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          :item-class="rowBackground"
          class="table thead-light tableListaUtenti"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-select
                    :items="['Tutti', 'Amministratore', 'Operatore']"
                    v-model="filterRuolo"
                    dense
                    flat
                    filled
                    solo
                    hide-details
                    height="43"
                    placeholder="Ruolo"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
											text-white
										"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-filter</v-icon
                      >
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                @click="dialogNew = true"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  ms-3
                "
                color="primary"
                >Aggiungi</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.consentiLogin="{ item }">
            <template v-if="item.consentiLogin && !item.archiviato">
              <v-icon color="success">fas fa-check</v-icon> Si
            </template>
            <template v-else>
              <v-icon color="error">fas fa-times</v-icon> No
            </template>
          </template>
          <template v-slot:item.azioni="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editUtente(item)"
                  elevation="0"
                  :ripple="false"
                  :disabled="item.archiviato"
                  height="28"
                  min-width="36"
                  width="36"
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-warning
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#fb6340"
                >
                  <v-icon x-small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="removeUtente(item['_id'])"
                  elevation="0"
                  :ripple="false"
                  :disabled="item.archiviato"
                  height="28"
                  min-width="36"
                  width="36"
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-danger
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#f5365c"
                >
                  <v-icon x-small>fas fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Elimina</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog
      v-model="dialogScheda"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <SchedaUtente
        v-if="dialogScheda"
        :isEditing="isEditingUtente"
        :id="idUtenteClicked"
        @chiudiDialogScheda="chiudiDialogScheda"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogNew"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewUtente v-if="dialogNew" @chiudiDialogNew="chiudiDialogNew" />
    </v-dialog>
  </v-container>
</template>

<script>
import apiUtenti from "@/js/pages/utenti";
import SchedaUtente from "@/components/SchedaUtente.vue";
import NewUtente from "@/components/NewUtente.vue";

export default {
  name: "listUtenti",
  components: {
    SchedaUtente,
    NewUtente,
  },
  data() {
    return {
      utenti: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "30%",
        },
        { text: "Matricola", value: "matricola", width: "15%" },
        { text: "Ruolo", value: "ruolo", width: "15%" },
        { text: "Email", value: "email", width: "15%" },
        { text: "Accesso", value: "consentiLogin", width: "10%" },
        { text: "Azioni", value: "azioni", width: "15%" },
      ],
      filterRuolo: undefined,
      isEditingUtente: false,
      dialogScheda: false,
      idUtenteClicked: undefined,
      dialogNew: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.utenti.length / this.itemsPerPage);
    }
  },
  watch: {
    async filterRuolo() {
      if (this.filterRuolo === "Tutti")
        this.utenti = await apiUtenti.getAll(this.token);
      else this.utenti = await apiUtenti.getAll(this.token, this.filterRuolo);
    },
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async chiudiDialogScheda(refresh = false) {
      if (refresh) await this.reloadLista();
      this.dialogScheda = false;
    },
    async chiudiDialogNew() {
      await this.reloadLista();
      this.dialogNew = false;
    },
    async reloadLista() {
      let utenti = await apiUtenti.getAll(this.token, this.filterRuolo);
      //Array.prototype.push.apply(utenti, utenti);
      this.utenti = utenti;
    },
    async editUtente(item) {
      this.idUtenteClicked = item["_id"];
      this.isEditingUtente = true;
      this.dialogScheda = true;
    },
    async removeUtente(id_utente) {
      if (confirm("Eliminare l'utente?")) {
        let data = { _id: id_utente };
        await apiUtenti.deleteOne(this.token, data);
        this.reloadLista();
      }
    },
    rowBackground(item) {
      return item.archiviato ? 'rowArchiviata' : '';
    }
  },
};
</script>