<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Scheda Utente</h2>
      <v-spacer></v-spacer>
      <!--v-btn small icon @click="remove_user">
        <v-icon small class="text-typo">fas fa-trash</v-icon>
      </v-btn>
      <v-btn small icon @click="isEditing = !isEditing" class="ml-3 mr-2">
        <v-icon small class="text-typo">fas fa-edit</v-icon>
      </v-btn-->
      <v-btn small icon @click="$emit('chiudiDialogScheda')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form v-model="formValid" ref="form_data" lazy-validation>
        <v-container>
          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Nome</label
          >
          <v-text-field
            hide-details="auto"
            required
            :rules="nomeRules"
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Nome"
            autocomplete="off"
            v-model="nome"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Matricola</label
          >
          <v-text-field
            hide-details="auto"
            required
            :rules="matricolaRules"
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Matricola"
            autocomplete="off"
            v-model="matricola"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Password</label
          >
          <v-text-field
            hide-details="auto"
            required
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Password"
            autocomplete="off"
            type="password"
            v-model="password"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-6
            "
          ></v-text-field>

          <v-checkbox
            v-model="consentiLogin"
            color="primary"
            :ripple="false"
            class="ma-0 checkbox-custom checkbox-thinner"
            hide-details
          >
            <template v-slot:label>
              <span class="text-muted text-body-2 ls-0">Consenti Login</span>
            </template>
          </v-checkbox>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Ruolo</label
          >
          <v-select
            hide-details="auto"
            :readonly="!isEditing"
            :rules="requiredRules"
            :items="['Amministratore', 'Operatore']"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              select-style
            "
            v-model="ruolo"
            outlined
            placeholder="Ruolo"
            single-line
          ></v-select>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-4"
            >Email</label
          >
          <v-text-field
            hide-details="auto"
            :readonly="!isEditing"
            :rules="emailRules"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Email"
            type="email"
            autocomplete="off"
            v-model="email"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Note</label
          >
          <v-textarea
            hide-details="auto"
            :readonly="!isEditing"
            outlined
            auto-grow
            rows="2"
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Note"
            autocomplete="off"
            v-model="note"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-textarea>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-col cols="4" class="pt-0">
          <v-btn
            color="primary"
            width="100%"
            @click="submit"
            :disabled="isEditing && !formValid && loading"
            v-text="isEditing ? 'Salva' : 'Indietro'"
          ></v-btn>
        </v-col>
        <v-spacer />
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import apiUtenti from "@/js/pages/utenti";
import {
  nomeRules,
  requiredRules,
  matricolaRules,
  emailValidaRules,
} from "@/js/validationRules";

export default {
  name: "schedaUtente",
  props: {
    id: String,
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      token: this.$cookies.get("token_goldencar"),
      email: "",
      nome: "",
      matricola: "",
      password: "",
      ruolo: "",
      showPwd: false,
      note: "",
      loading: false,
      permesso: undefined,
      utente: {},
      formValid: false,
      emailRules: emailValidaRules,
      nomeRules: nomeRules,
      matricolaRules: matricolaRules,
      requiredRules: requiredRules,
      consentiLogin: true,
    };
  },
  async created() {
    let this_id = this.id;
    if (!this_id) this_id = this.$route.query._id;
    let utente = await apiUtenti.getUtenteFromId(this_id, this.token);
    this.nome = utente.nome || "";
    this.matricola = utente.matricola || "";
    this.ruolo = utente.ruolo || "";
    this.email = utente.email || "";
    this.note = utente.note || "";
    this.consentiLogin = utente.consentiLogin || false;
  },
  methods: {
    async submit() {
      if (this.isEditing) {
        if(!this.loading) {
          this.loading = true;
          if (this.$refs.form_data.validate() && this.formValid) {
            let data = {
              _id: this.id,
              nome: this.nome,
              email: this.email,
              matricola: this.matricola,
              password: this.password,
              note: this.note,
              ruolo: this.ruolo,
              consentiLogin: this.consentiLogin,
            };
            let update = await apiUtenti.updateOne(this.token, data);
            if (update === "matricolaEsistente") alert("Matricola esistente!");
            else if (update) this.$emit("chiudiDialogScheda", true);
            else alert("Errori durante la creazione, ricontrolla i dati");
          }
          this.loading = false;
        }
      } else this.$emit("chiudiDialogScheda");
    },
  },
};
</script>
